<template>
  <v-dialog @input="change($event)" :value="excelProp" max-width="500">
    <v-card>
      <v-card-title class="font-weight-bold"> סינון הזמנות באקסל </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-row class="pt-5">
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                dense
                type="date"
                :rules="[(v) => !!v || 'שדה חובה']"
                v-model="excelFilter.start_date"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  מתאריך
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                dense
                type="date"
                :rules="[(v) => !!v || 'שדה חובה']"
                v-model="excelFilter.end_date"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  עד תאריך
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                outlined
                dense
                type="number"
                label="מינינום הזמנה"
                v-model="excelFilter.amount"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          @click="download()"
          color="primary"
          :loading="download_loader"
          :disabled="download_loader"
          large
          >download</v-btn
        >
        <v-btn @click="close()" large :disabled="download_loader">cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DownloadExcel",
  props: ["excelProp"],
  data() {
    return {
      excelFilter: {
        start_date: null,
        end_date: null,
        amount: null,
      },
      download_loader: false,
    };
  },
  methods: {
    change(ev) {
      if (!ev) {
        this.close();
      }
    },
    download() {
      if (this.$refs.form.validate() && !this.download_loader) {
        this.download_loader = true;
        this.axios({
          url: "/orders/excel",
          method: "post",
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`,
          },
          data: this.excelFilter,
        }).then((res) => {
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, "0");
          var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          var yyyy = today.getFullYear();
          today = mm + "/" + dd + "/" + yyyy;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `orders-${today}.xlsx`);
          document.body.appendChild(link);
          link.click();
          this.close();
        });
      }
    },
    close() {
      this.$emit("closeDownloadExcel");
      this.excelFilter = {
        start_date: null,
        end_date: null,
        amount: 0,
      };
      this.download_loader = false;
      this.$refs.form.resetValidation();
    },
  },
};
</script>
