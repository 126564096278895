<template>
  <v-dialog
    @input="$emit('close_select_delivery')"
    max-width="500"
    :value="select_delivery_prop"
  >
    <v-card>
      <v-card-title> חיבור לשליח </v-card-title>
      <v-card-text>
        <v-form ref="form" class="mt-5">
          <v-select
            :rules="[(v) => !!v || 'שדה חובה']"
            outlined
            v-model="order.delivery_id"
            item-text="name"
            item-value="id"
            :items="deliveries"
          >
            <template slot="label">
              <strong class="red--text">*</strong>
              שליח
            </template>
          </v-select>
          <v-text-field
            v-if="order.payment_method == 'cash'"
            outlined
            label="עלות משלוח"
            type="number"
            v-model="order.delivery_cost"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          color="black white--text lighten-2"
          :loading="save_loader"
          :disabled="save_loader"
          @click="save()"
          >שמירה</v-btn
        >
        <v-btn
          :disabled="save_loader"
          @click="$emit('close_select_delivery')"
          color="error"
          text
          >ביטול</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["select_delivery_prop", "order"],
  name: "SelectDelivery",
  data() {
    return {
      deliveries: [],
      save_loader: false,
    };
  },
  methods: {
    get_deliveries() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "/delivery",
            method: "get",
          },
        })
        .then((res) => {
          this.deliveries = Object.assign([], res.data.data);
        })
        .catch((err) => {});
    },
    save() {
      if (
        this.$refs.form.validate() &&
        this.order.status != "done" &&
        this.order.status != "canceled"
      ) {
        this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `update_delivery/${this.order.id}`,
              method: "put",
            },
            data: {
              delivery_id: this.order.delivery_id,
              delivery_cost:
                this.order.payment_method == "cash"
                  ? this.order.delivery_cost
                  : null,
            },
          })
          .then((res) => {
            this.$emit("update_data");
            this.save_loader = false;
          });
      }
    },
  },
  mounted() {
    this.get_deliveries();
  },
};
</script>
