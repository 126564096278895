<template>
  <v-dialog
    @input="dialog_change($event)"
    :value="ShowOrderDialog"
    max-width="800"
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="body-1"> # {{ order.order_num }} </span>
        <v-spacer></v-spacer>
        <span class="body-1">
          {{ order.new_time }}
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <!--/card title -->
      <v-card-text>
        <v-list dense>
          <v-list-item-title class="pb-3">
            <h3>العميل</h3>
          </v-list-item-title>
          <v-list-item>
            <v-list-item-content>
              <div class="d-flex justify-space-between black--text">
                <router-link
                  class="fw-bold text-decoration-none"
                  :to="`/customer`"
                >
                  {{ order.customer.name }}</router-link
                >
                <a
                  class="fw-bold text-decoration-none blue--text"
                  :href="`tel:${order.customer.phone}`"
                >
                  {{ order.customer.phone }}
                  <v-icon small>phone</v-icon>
                </a>
                <span
                  >{{ order.customer.city ? order.customer.city : "__" }}
                </span>
              </div>
              <div class="mt-3">
                {{ order.address ? order.address.address_name : "__" }} -
                {{ order.address ? order.address.special_marque : "__" }}
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <!--/ customer -->
          <v-list-item-title class="pb-3 mt-4">
            <h3>الديلفيري</h3>
          </v-list-item-title>
          <v-list-item>
            <v-list-item-content>
              <div
                v-if="order.delivery"
                class="d-flex justify-space-between black--text"
              >
                <div>
                  <p>
                    {{ order.delivery.name }}
                  </p>
                  <p v-if="normalAddress" class="mt-2 font-weight-bold">
                    {{ `${order.address.address} - ${order.address.place}` }}
                  </p>
                </div>
                <span class="fw-bold text-decoration-none blue--text">
                  {{ order.delivery.phone }}
                  <v-icon small>phone</v-icon>
                </span>
              </div>
              <span v-else> __ </span>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <!--/ delivery -->

          <v-list-item class="d-block mt-3">
            <v-list-item-content style="width: 100%">
              <ul class="pa-0" style="width: 100%">
                <li class="d-flex justify-space-between mb-3">
                  <span class="font-weight-bold">
                    الكـــــــــــــــــــــــــــــــوبون :
                  </span>
                  <span>{{
                    order.coupon ? order.coupon.coupon : "لايوجد"
                  }}</span>
                </li>
              </ul>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <v-list-item-title class="pb-3 mt-4">
            <h3>المنتجات</h3>
          </v-list-item-title>

          <v-list-item v-for="(pro, i) in order.products" :key="i">
            <v-list-item-action v-if="order.status == 'preparation'">
              <v-btn
                :laoding="ready_loading"
                :disabled="ready_loading"
                @click="product_is_ready(pro, order.products)"
                icon
                x-large
              >
                <v-icon v-if="!pro.ready_product"
                  >check_box_outline_blank</v-icon
                >
                <v-icon v-else color="primary">check_box</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-content>
              <div
                class="d-flex align-center justify-space-between black--text"
              >
                <div>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>error_outline</v-icon></v-btn
                      >
                    </template>
                    <span>{{ pro.name }}</span>
                  </v-tooltip>
                  <p>
                    <v-chip color="grey white--text" x-small class="px-1">
                      {{ pro.barcode.slice(pro.barcode.length - 3) }}
                    </v-chip>
                  </p>
                </div>
                <img
                  v-if="pro.img"
                  :src="`${$store.state.def_path}/${pro.img}`"
                  width="80"
                  height="80"
                  alt=""
                />
                <img
                  width="80"
                  height="80"
                  v-if="!pro.img"
                  :src="`${$store.state.def_path}/uploads/none_img.png`"
                  alt=""
                />

                <span>
                  <v-chip color="primary" small>
                    x
                    <span style="font-size: 20px; font-weight: bold">{{
                      pro.quantity
                    }}</span>
                  </v-chip>
                </span>

                <span> {{ pro.sku }}</span>

                <router-link
                  class="fw-bold text-decoration-none"
                  :to="`/product/${pro.product_id}`"
                >
                  {{ pro.name.slice(0, 18) }}
                  {{ pro.name.length >= 18 ? "..." : "" }}
                </router-link>

                <span> ₪ {{ pro.price }} </span>
                <span class="font-weight-bold"
                  >{{ pro.price * pro.quantity }}
                </span>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-block">
        <div>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <div class="d-flex justify-space-between black--text">
                  <span> اجمالي المنتجات</span>
                  <span> </span>
                  <span> </span>
                  <span class="font-weight-bold">₪{{ order.total }} </span>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <div class="d-flex justify-space-between black--text">
                  <span> تكلفة التوصيل</span>
                  <span> </span>
                  <span> </span>
                  <span class="font-weight-bold"
                    >₪{{ order.delivery_cost }}
                  </span>
                </div>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="order.coupon">
              <v-list-item-content>
                <div class="d-flex justify-space-between black--text">
                  <span> خصم الكوبون</span>
                  <span> </span>
                  <span> </span>
                  <span class="font-weight-bold"
                    >₪{{ order.coupon ? couponCost : 0 }}
                  </span>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="order.walletDiscount">
              <v-list-item-content>
                <div class="d-flex justify-space-between black--text">
                  <span> خصم المحفظة</span>
                  <span> </span>
                  <span> </span>
                  <span class="font-weight-bold"
                    >₪{{ order.walletDiscount }}
                  </span>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="grey lighten-3">
              <v-list-item-content>
                <div class="d-flex justify-space-between black--text">
                  <span class="font-weight-bold"> اجمالي الدفع</span>
                  <span> </span>
                  <span> </span>
                  <span class="font-weight-bold"> ₪{{ totalOrder() }} </span>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row
            class="mt-3"
            v-if="$store.getters.permission('customer_wallet')"
          >
            <v-col cols="4">
              <v-text-field
                outlined
                readonly
                background-color="#f0f0f0"
                dense
                :value="order.total"
                label="تكلفة الطلب"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                outlined
                :rules="[
                  () => !(order.addedWallet > order.total) || 'اكبر من الطلب',
                ]"
                dense
                :value="order.addedWallet"
                @input="$emit('walletInput', $event)"
                label="سعر الكاشير"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                outlined
                readonly
                background-color="#f0f0f0"
                dense
                :value="casherDiscount"
                label="مبلغ المحفظة"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-btn
          v-if="order.status == 'preparation' && products_ready"
          :disabled="loading_update_status"
          :loading="loading_update_status"
          @click="$emit('update_status', order)"
          color="green darken-2 white--text"
          large
          >تم التجهيز</v-btn
        >
        <v-btn
          :disabled="loading_update_status"
          large
          @click="$emit('close_show_order')"
          color="error"
          small
          >الغاء</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [
    "ShowOrderDialog",
    "order",
    "loading_update_status",
    "casherDiscount",
  ],
  name: "ShowOrder",
  data() {
    return {
      ready_loading: false,
    };
  },
  computed: {
    products_ready() {
      const products = this.order.products;
      let all_ready = true;
      for (let i = 0; i < products.length; i++) {
        if (!products[i].ready_product) {
          all_ready = false;
          break;
        }
      }

      return all_ready;
    },
    normalAddress() {
      return (
        this.order.address &&
        !this.order.address.latitude &&
        !this.order.address.langitude
      );
    },
    couponCost() {
      return this.order.total <= this.order.coupon.discount
        ? this.order.total
        : this.order.coupon.discount;
    },
  },
  methods: {
    dialog_change(ev) {
      if (!ev) {
        this.$emit("close_show_order");
      }
    },
    product_is_ready(pro, products) {
      if (!this.ready_loading) {
        pro.ready_product = !pro.ready_product;
        this.ready_loading = true;

        this.$store
          .dispatch("public__request", {
            config: {
              url: `product_is_ready/${pro.id}`,
              method: "put",
            },
          })
          .then((res) => {
            this.ready_loading = false;
          });
      }
    },
    totalOrder() {
      let final = 0;
      if (this.order.coupon) {
        final =
          this.order.total - this.order.coupon.discount < 0
            ? 0
            : this.order.total - this.order.coupon.discount;
        final += this.order.delivery_cost;
      } else {
        final =(this.order.total - this.order.walletDiscount) + this.order.delivery_cost;
      }
      return final;
    },
  },
  mounted() {},
};
</script>
