<template>
  <v-container fluid @click="restNotifications()">  
    <div class="d-block d-md-flex">
      <AppAside></AppAside>
      <div class="ps-5 page_content grow">
        <v-skeleton-loader v-if="!data_loaded" type="table"></v-skeleton-loader>
        <v-card v-if="data_loaded">
          <v-card-title class="align-center">
            <div class="d-flex align-center">
              <v-icon x-large>local_mall</v-icon>
              <h1 class="ms-3">הזמנות</h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
              <v-btn @click="excelProp = true" text fab small>
                <img src="@/assets/excel.png" alt="" />
              </v-btn>
              <v-text-field
                outlined
                dense
                hide-details
                label="מספר הזמנה"
                v-model="search_key"
                class="mx-3"
              ></v-text-field>
              <v-btn outlined color="primary" @click="search_order()"
                >חיפוש</v-btn
              >
            </div>
          </v-card-title>
          <v-data-table
            class="order_table"
            :mobile-breakpoint="0"
            :hide-default-footer="true"
            :disable-pagination="true"
            :items="items"
            :headers="headers"
          >
            <template v-slot:[`item.customer`]="{ item }">
              <v-chip
                small
                :color="item.is_special ? '#ffd700' : 'transparent'"
              >
                {{
                  item.customer.name.length >= 15
                    ? item.customer.name.slice(0, 15) + "..."
                    : item.customer.name
                }}
              </v-chip>
            </template>

            <template v-slot:[`item.address`]="{ item }">
              <v-chip
                x-small
                class="px-2"
                :color="item.address ? 'green' : 'red white--text'"
              >
                {{ item.address ? "כן" : "לא" }}
              </v-chip>
            </template>

            <template v-slot:[`item.delivery_method`]="{ item }">
              <v-chip
                small
                label
                class="px-2 black--text"
                :color="item.delivery_method ? '' : 'warning'"
              >
                <v-icon>
                  {{ item.delivery_method ? "handshake" : "house" }}
                </v-icon>
              </v-chip>
            </template>

            <template v-slot:item.payment_method="{ item }">
              {{ item.payment_method == "visa" ? "אשראי" : "מזומן" }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-btn
                class="me-2"
                v-if="item.status == 'preparation'"
                depressed
                x-small
                fab
                color="grey white--text"
                @click="show_order(item)"
              >
                <v-icon x-small>visibility</v-icon>
              </v-btn>

              <v-btn
                color="primary elevation-0 me-2"
                fab
                x-small
                :disabled="update_status_prop"
                :loading="update_status_prop"
                v-if="
                  item.status != 'canceled' &&
                  item.status != 'done' &&
                  item.status != 'preparation'
                "
                @click="update_status(item)"
              >
                <v-icon>rv_hookup</v-icon>
              </v-btn>

              <v-chip
                class="px-2"
                x-small
                color="warning"
                v-if="item.status == 'pending'"
              >
                בהמתנה
              </v-chip>
              <v-chip
                class="px-2"
                x-small
                color="grey lighten-2"
                v-if="item.status == 'new'"
              >
                חדש
              </v-chip>
              <v-chip
                class="px-2"
                x-small
                color="warning darken-1"
                v-else-if="item.status == 'preparation'"
              >
                בהכנה
              </v-chip>

              <v-chip
                class="px-2"
                x-small
                color="pink success"
                v-else-if="item.status == 'ready'"
              >
                מוכן
              </v-chip>

              <v-chip
                class="px-2"
                x-small
                color="blue lighten-2 white--text"
                v-else-if="item.status == 'in_progress_delivery'"
              >
                בדרך
              </v-chip>
              <v-chip
                class="px-2"
                x-small
                color="priamry lighten-2"
                v-else-if="item.status == 'delivery_arrived'"
              >
                הגיע השליח
              </v-chip>

              <v-chip
                class="px-2"
                x-small
                color="green darken-1 white--text"
                v-else-if="item.status == 'done'"
              >
                טופל
              </v-chip>

              <v-chip
                class="px-2"
                x-small
                color="red lighten-2 white--text"
                v-else-if="item.status == 'canceled'"
              >
                מבוטלת
              </v-chip>
            </template>
            <template v-slot:[`item.delivery`]="{ item }">
              <span>
                <v-btn
                  v-if="
                    $store.getters.permission('orders edit') &&
                    item.status != 'done' &&
                    item.status != 'canceled' &&
                    item.delivery_method != 1
                  "
                  @click="select_delivery(item)"
                  :color="item.delivery ? 'grey lighten-2' : 'primary'"
                  x-small
                  rounded
                  depressed
                >
                  {{ item.delivery ? item.delivery.name : "הוספת" }}
                  <v-icon small color="success darken-2">add</v-icon>
                </v-btn>
                <span v-else>
                  {{ item.delivery ? item.delivery.name : "__" }}
                </span>
              </span>
            </template>

            <template v-slot:[`item.delivery_cost`]="{ item }">
              ₪{{ item.delivery_cost }}
            </template>
            <template v-slot:[`item.coupon`]="{ item }">
              ₪{{ couponCost(item) }}
            </template>
            <template v-slot:[`item.total_products`]="{ item }">
              ₪{{ item.total }}
            </template>
            <template v-slot:[`item.total`]="{ item }">
              ₪{{ totalOrder(item) }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                v-if="item.invoice"
                target="_blank"
                :href="`${$store.state.def_path}/${item.invoice}`"
                small
                icon
                download
              >
                <v-icon small>receipt_long</v-icon>
              </v-btn>

              <v-btn
                v-if="
                  item.address &&
                  item.address.longitude &&
                  item.address.latitude
                "
                target="_blank"
                :href="`https://waze.com/ul?ll=${item.address.longitude},${item.address.latitude}`"
                small
                icon
              >
                <img src="@/assets/waze.png" width="15" />
              </v-btn>

              <v-btn
                v-if="item.address"
                target="_blank"
                :href="`https://www.google.com/maps?q=${item.address.latitude},${item.address.longitude}`"
                small
                icon
              >
                <v-icon small> location_on </v-icon>
              </v-btn>
              <v-btn icon small @click="show_order(item)">
                <v-icon small>visibility</v-icon>
              </v-btn>

              <v-btn
                v-if="
                  item.delivery && item.delivery_method == 0 && item.address
                "
                small
                icon
                target="_blank"
                :href="`https://wa.me/${
                  item.delivery.phone
                }?text=${whatsapp_msg(item)}`"
              >
                <img src="@/assets/whatsapp.png" width="15" />
              </v-btn>

              <v-btn
                v-if="item.status != 'done' && item.status != 'canceled'"
                small
                icon
                @click="cancele_order(item)"
              >
                <v-icon color="red">close</v-icon>
              </v-btn>
            </template>
            <!--/ actions -->
          </v-data-table>
        </v-card>
        <template>
          <div
            style="margin-bottom: 100px"
            v-if="data_loaded"
            class="text-center mt-4 d-flex justify-center"
          >
            <v-pagination
              @input="get_data()"
              v-model="page"
              total-visible="7"
              :length="total"
              circle
            ></v-pagination>
          </div>
        </template>

        <DownloadExcel
          :excelProp="excelProp"
          @closeDownloadExcel="excelProp = false"
        />
        <SelectDelivery
          @close_select_delivery="select_delivery_prop = false"
          @update_data="get_data(), (select_delivery_prop = false)"
          v-if="select_delivery_prop"
          :select_delivery_prop="select_delivery_prop"
          :order="order"
        />
        <ShowOrder
          :loading_update_status="loading_update_status"
          @update_status="update_status($event)"
          @walletInput="($event) => (order.addedWallet = $event)"
          :casherDiscount="casherDiscount"
          @close_show_order="(ShowOrderDialog = false), (order = null)"
          v-if="order"
          :ShowOrderDialog="ShowOrderDialog"
          :order="order"
        />
      </div>
    </div>

    <DeleteDialog
      :approve="`כן`"
      :cancel="`לא`"
      @delete_done="get_data()"
      @close_delete="delete_obj.delete_prop = false"
      :delete_obj="delete_obj"
    ></DeleteDialog>
  </v-container>
</template>

<script>
import SelectDelivery from "@/components/orders/SelectDelivery";
import ShowOrder from "@/components/orders/ShowOrder";
import DownloadExcel from "@/components/orders/DownloadExcel";
import DeleteDialog from "@/components/public/delete";
export default {
  name: "orders",
  data() {
    return {
      loading_update_status: false,
      search_key: "",
      update_status_prop: false,
      select_delivery_prop: false,
      page: 1,
      data_loaded: false,
      items: [],
      total: 0,
      order: null,
      delete_obj: {
        delete_prop: false,
        delete_url: "cancel_order",
        title: "ביטול הזמנה ؟ ",
        id: null,
      },

      ShowOrderDialog: false,
      order: null,
      excelProp: false,
    };
  },
  components: {
    SelectDelivery,
    ShowOrder,
    DeleteDialog,
    DownloadExcel,
  },

  computed: {
    headers() {
      const headers = [
        {
          value: "created_at",
          text: "תאריך",
          align: "center",
        },
        {
          value: "order_num",
          text: "מספר הזמנה",
          align: "center",
        },
        {
          value: "address",
          text: "כתובת",
          align: "center",
        },
        {
          value: "delivery_method",
          text: "delivery_method",
          align: "center",
        },
        {
          value: "status",
          text: "סטטוס",
          align: "center",
        },
        {
          value: "payment_method",
          text: "סוג תשלום",
          align: "center",
        },
        {
          value: "customer",
          text: "לקוח",
          align: "center",
        },
        {
          value: "delivery",
          text: "שליח",
          align: "center",
        },
        {
          value: "delivery_cost",
          text: "עלות משלוח",
          align: "center",
        },
        {
          value: "coupon",
          text: "coupon",
          align: "center",
          has_permission: true,
          permission: this.$store.getters.permission("coupons access"),
        },
        {
          value: "walletDiscount",
          text: "walletDiscount",
          align: "center",
          has_permission: true,
          permission: this.$store.getters.permission("customer_wallet"),
        },
        {
          value: "total_products",
          text: "סה”כ",
          align: "center",
        },
        {
          value: "total",
          text: "סה”כ",
          align: "center",
        },
        {
          value: "actions",
          text: "פעולות",
          align: "center",
        },
      ];

      return headers.filter(
        (e) => !e.has_permission || (e.has_permission && e.permission)
      );
    },
    couponCost: (app) => (order) => {
      if (order.coupon) {
        return order.total <= order.coupon.discount
          ? order.total
          : order.coupon.discount;
      }
      return 0;
    },
    casherDiscount() {
      if (!this.order || this.order && this.order.addedWallet <= 0) {
        return 0;
      } else {
        return this.order ? this.order.total - this.order.addedWallet : 0;
      }
    },
    whatsapp_msg: (app) => (order) => {
      return `הזמנה חדשה מס : ${order.order_num} %0a
      לקוח: ${order.customer.name}%0a
      מספר לקוח: ${order.customer.phone}%0a
      איזור :  ${order.address.place}%0a
      סה”כ משלוח :   ${order.delivery_cost}%0a
      סה”כ הזמנה :  ${order.total}%0a
      סה״כ לתשלום":  ${
        order.total - app.couponCost(order) + order.delivery_cost
      }%0a
      הערה לכתובת :  ${order.address.address}    %0a
      `;
    },
  },
  watch:{
    '$store.getters.notifications'() {
      this.get_data();
    }
  },
  methods: {
    get_data() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `orders${this.search_key ? "/" + this.search_key : ""}?page=${
              this.page
            }`,
            method: "get",
          },
        })
        .then((res) => {
          this.items = Object.assign([], res.data.data);
          this.total = res.data.meta.last_page;
          this.page = res.data.meta.current_page;
          this.data_loaded = true;
        });
    },
    search_order() {
      this.page = 1;
      this.get_data();
    },
    select_delivery(order) {
      this.order = Object.assign({}, order);
      this.select_delivery_prop = true;
    },
    show_order(order) {
      order.products
        .sort((a, b) => {
          const numeric = /^[-+]??\d+/;

          const isNumericA = numeric.test(a.sku);
          const isNumericB = numeric.test(b.sku);
          // e.g. compare 5 and 6
          if (isNumericA && isNumericB) {
            return parseInt(a.sku, 10) - parseInt(b.sku, 10);
          }

          // e.g. 5 and A2
          if (isNumericA && !isNumericB) {
            return -1;
          }

          // e.g. A2 and 6
          if (!isNumericA && isNumericB) {
            return 1;
          }

          const alphabets = /^[a-zA-Z]+/;
          // Alphabet + number: A1, B3...
          const aAlphabets = a.sku.replace(/\d+/g, "");
          const bAlphabets = b.sku.replace(/\d+/g, "");
          if (aAlphabets === bAlphabets) {
            // e.g. Compare AB10 and AB12
            const aNumber = a.sku.replace(alphabets, "");
            const bNumber = b.sku.replace(alphabets, "");
            // e.g. Compare 10 and 12 for AB10 and AB12
            const result =
              aNumber === bNumber
                ? 0
                : parseInt(aNumber, 10) - parseInt(bNumber, 10);
            console.log(`A: ${a.sku}, B: ${b.sku}, result: ${result}`);
            return result;
          }
          // e.g. A12 and B12
          return aAlphabets > bAlphabets ? 1 : -1;
        })
        .join(",");

      this.order = order;

      this.$nextTick(() => {
        this.ShowOrderDialog = true;
      });
    },
    update_status(order) {
      if (
        !this.update_status_prop &&
        this.$store.getters.permission("orders edit")
      ) {
        this.update_status_prop = true;
        this.loading_update_status = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `status/${order.id}}`,
              method: "put",
            },
            data: {
              walletAmount: order.addedWallet,
            },
          })
          .then((res) => {
            order.status = res.data.data.status;

            if (res.data.data.status == "done") {
              this.$store.dispatch("active_orders");
            }
            this.$nextTick(() => {
              this.update_status_prop = false;
            });
            this.loading_update_status = false;
          });
      }
    },
    cancele_order(order) {
      this.delete_obj.id = order.id;
      this.delete_obj.delete_prop = true;
    },

    totalOrder(order) {
      let final = 0;
      if (order.coupon) {
        final =
          order.total - order.coupon.discount < 0
            ? 0
            : order.total - order.coupon.discount;
        final += order.delivery_cost;
      } else {
        final = order.total - order.walletDiscount + order.delivery_cost;
      }
      return final;
    },
    restNotifications() {
      this.$store.commit('resetNotifications');
    }
  },
  mounted() {
    this.get_data();
  },
};
</script>

<style lang="scss">
.order_table {
  td:nth-of-type(3),
  td:nth-of-type(4),
  td:nth-of-type(9),
  td:nth-of-type(10),
  td:nth-of-type(11),
  td:nth-of-type(12) {
    width: 40px;
  }
}
</style>
